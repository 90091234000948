import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-modal", { attrs: { "ok-text": "Save", "title": "Edit Procurement Specialists", "after-close": _vm.afterModalClose, "width": 520 }, scopedSlots: _vm._u([{ key: "footer", fn: function() {
      return [_c("a-button", { on: { "click": _vm.onCancel } }, [_vm._v("Cancel")]), _c("a-button", { attrs: { "loading": _vm.isLoading, "type": "primary" }, on: { "click": function($event) {
        return handleSubmit(_vm.submit);
      } } }, [_vm._v("Save")])];
    }, proxy: true }], null, true), model: { value: _vm.visible, callback: function($$v) {
      _vm.visible = $$v;
    }, expression: "visible" } }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 24 } }, [_c("text-input", { key: "procurementSpecialist", attrs: { "label": "Procurement Specialist", "span": 12, "placeholder": "Type Sort Order", "value": _vm.entity.procurementSpecialist.trim(), "rules": "required", "form-item": "" }, on: { "change": _vm.onProcurementSpecialist } })], 1), _c("a-col", { attrs: { "span": 24 } }, [_c("select-input", { key: "specialistGroup", attrs: { "label": "Specialist Group", "reference": "ppv.common.ppv-procurement-specialist-group", "placeholder": "Select Country", "source": "specialistGroup", "rules": "required", "span": 12, "value": _vm.entity.specialistGroup, "form-item": "" }, on: { "change": _vm.onSpecialistGroup } })], 1)], 1)], 1)];
  } }]) });
};
var staticRenderFns$1 = [];
var EditProcurementSpecialists_vue_vue_type_style_index_0_lang = "";
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "EditProcurementSpecialists",
  inject: ["crud"],
  data() {
    return {
      apiUrl: apiUrl$1,
      visible: false,
      isLoading: false,
      procurementSpecialist: "",
      specialistGroup: ""
    };
  },
  computed: {
    entity() {
      return this.crud.getEntity();
    }
  },
  mounted() {
    this.visible = true;
  },
  methods: {
    afterModalClose() {
      this.$router.push("/ppv-actuals/procurement-specialists");
    },
    onCancel() {
      this.visible = false;
    },
    onProcurementSpecialist(value) {
      this.procurementSpecialist = value;
    },
    onSpecialistGroup(value) {
      this.specialistGroup = value;
    },
    async submit() {
      this.isLoading = true;
      await this.axios.put(`${this.apiUrl}/ppv/procurement-specialist/${this.entity.procSpecId}`, {
        ProcurementSpecialist: this.procurementSpecialist ? this.procurementSpecialist : this.entity.procurementSpecialist,
        SpecialistGroup: this.specialistGroup ? this.specialistGroup : this.entity.specialistGroup
      }).then(() => {
        this.isLoading = false;
        this.visible = false;
        this.crud.fetchList();
        this.$notification["success"]({
          message: "Item Updated!"
        });
      });
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditProcurementSpecialists = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "name": "ppv.common.ppv-procurement-specialist-group", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "ppv.procurement-specialist", "api-url": _vm.apiUrl, "redirect-route": "/ppv-actuals/procurement-specialists" } }, [_c("edit-procurement-specialists")], 1)], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_lang = "";
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditProcurementSpecialists
  },
  data() {
    return {
      apiUrl,
      EditProcurementSpecialists
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
